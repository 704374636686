import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["radio", "checkbox", "link"]

  connect() {
    this.updateVisibility()

    if(this.hasLinkTarget) {
      const showTargetAttribute = this.linkTarget.getAttribute("data-show-target")
      const target = document.querySelector(showTargetAttribute)
      hideTarget(target)
    }
  }

  updateVisibility() {
    if(this.hasRadioTarget) {
      const radio = this.radioTargets.find(input => input.checked)
      const value = radio.value

      if(radio.hasAttribute("data-hide-value")) {
        const hideValueAttributes = radio.getAttribute("data-hide-value").split(",")
        const hideTargetAttribute = radio.getAttribute("data-hide-target")
        const hideTarget = document.querySelector(hideTargetAttribute)

        if(hideValueAttributes.includes(value)) {
          hideTarget.classList.remove('d-none')
        }
        else {
          hideTarget.classList.add('d-none')
        }
      }
      else if(radio.hasAttribute("data-show-value")) {
        const showValueAttributes = radio.getAttribute("data-show-value").split(",")
        const showTargetAttribute = radio.getAttribute("data-show-target")
        const showTarget = document.querySelector(showTargetAttribute)

        if(showValueAttributes.includes(value)) {
          showTarget.classList.remove('d-none')
        }
        else {
          showTarget.classList.add('d-none')
        }
      }
    }

    if(this.hasCheckboxTarget) {
      const checked = this.checkboxTarget.checked
      const hideValueAttributes = this.checkboxTarget.getAttribute("data-hide-value")
      const hideTargetAttribute = this.checkboxTarget.getAttribute("data-hide-target")
      const hideTarget = document.querySelector(hideTargetAttribute)

      if(hideValueAttributes === `${checked}`) {
        hideTarget.classList.add('d-none')
      }
      else {
        hideTarget.classList.remove('d-none')
      }
    }

    if(this.hasLinkTarget) {
      const hideTargetAttribute = this.linkTarget.getAttribute("data-hide-target")
      const showTargetAttribute = this.linkTarget.getAttribute("data-show-target")
      const hideTarget = document.querySelector(hideTargetAttribute)
      const showTarget = document.querySelector(showTargetAttribute)

      if(showTargetAttribute) {
        showTarget.classList.add('d-none')
      }
    }
  }

  linkClicked(e) {
    e.preventDefault()

    const hideTargetAttribute = this.linkTarget.getAttribute("data-hide-target")
    const showTargetAttribute = this.linkTarget.getAttribute("data-show-target")
    const hideTarget = document.querySelector(hideTargetAttribute)
    const showTarget = document.querySelector(showTargetAttribute)

    if(hideTargetAttribute) {
      hideTarget.classList.add('d-none')
    }

    if(showTargetAttribute) {
      showTarget.classList.remove('d-none')
    }
  }

  handleClick(e) {
    e.preventDefault()
    hideTarget(this.linkTarget)

    if(this.hasLinkTarget) {
      const showTargetAttribute = this.linkTarget.getAttribute("data-show-target")
      const target = document.querySelector(showTargetAttribute)
      showTarget(target)
    }
  }
}
