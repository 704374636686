function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

function hideTarget(target) {
  if(isIterable(target)) {
    target.forEach(t => hideTarget(t))
  }
  else {
    target.classList.add('d-none')
  }
}

function showTarget(target) {
  if(isIterable(target)) {
    target.forEach(t => showTarget(t))
  }
  else {
    target.classList.remove('d-none')
  }
}


export { hideTarget, showTarget }
