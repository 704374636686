import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["questionType", "label", "instructions", "options", "hint", "required", "token", "webhookSendFormatWrapper", "webhookSendFormat"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const questionType = this.questionTypeTarget.value

    switch(questionType) {
      case "html":
        hideTarget(this.labelTarget)
        showTarget(this.instructionsTarget)
        hideTarget(this.optionsTarget)
        hideTarget(this.hintTarget)
        hideTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          hideTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      case "text_box":
        showTarget(this.labelTarget)
        hideTarget(this.instructionsTarget)
        hideTarget(this.optionsTarget)
        showTarget(this.hintTarget)
        showTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          showTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      case "text_field":
        showTarget(this.labelTarget)
        hideTarget(this.instructionsTarget)
        hideTarget(this.optionsTarget)
        showTarget(this.hintTarget)
        showTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          showTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      case "dropdown":
        showTarget(this.labelTarget)
        hideTarget(this.instructionsTarget)
        showTarget(this.optionsTarget)
        showTarget(this.hintTarget)
        showTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          showTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      case "radio":
        showTarget(this.labelTarget)
        hideTarget(this.instructionsTarget)
        showTarget(this.optionsTarget)
        showTarget(this.hintTarget)
        showTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          showTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      case "checkbox_group":
        showTarget(this.labelTarget)
        hideTarget(this.instructionsTarget)
        showTarget(this.optionsTarget)
        showTarget(this.hintTarget)
        showTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          showTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      case "color_picker":
        showTarget(this.labelTarget)
        hideTarget(this.instructionsTarget)
        hideTarget(this.optionsTarget)
        showTarget(this.hintTarget)
        showTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          showTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      case "file":
        showTarget(this.labelTarget)
        hideTarget(this.instructionsTarget)
        hideTarget(this.optionsTarget)
        showTarget(this.hintTarget)
        showTarget(this.requiredTarget)
        if(this.hasWebhookSendFormatWrapperTarget) {
          showTarget(this.webhookSendFormatWrapperTarget)
        }
        break;
      default:
        console.warn(`Unknown kind: ${questionType}`)
        break;
    }

    if(this.hasWebhookSendFormatWrapperTarget) {
      const webhookSendFormat = this.webhookSendFormatTarget.value
      if(webhookSendFormat === "json") {
        showTarget(this.tokenTarget)
      }
      else {
        hideTarget(this.tokenTarget)
      }
    }
  }
}
