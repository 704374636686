import React from 'react';

const ResourcePreview = ({ mediaLibraryResource, onSelect }) => {

  function handleClick (e) {
    e.preventDefault()
    onSelect(mediaLibraryResource)
  }

  return (
    <div className="mb-4 resource-preview-container">
      <h5 className="text-center">{mediaLibraryResource.attributes.name}</h5>
      <a href="#" onClick={handleClick}>
        <img src={mediaLibraryResource.attributes.url} className="img-fluid resource-preview-image" />
      </a>
    </div>
  )
}

export default ResourcePreview;
