import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Nav, Row, Col } from 'react-bootstrap'
import { buildApiClient } from '../../../helpers/client'
import Gallery from "./Gallery"
import MediaLibraryTypeSelector from "./MediaLibraryTypeSelector"
import MediaLibraryCategorySelector from "./MediaLibraryCategorySelector"
import Loader from './Loader'

const ModalMediaLibraryBrowser = ({ apiKey, mediaLibraryUuid }) => {
  const [ready, setReady] = useState(false)
  const [mediaLibraryTypeValue, setMediaLibraryTypeValue] = useState('uploads')
  const [mediaLibraryTypes, setMediaLibraryTypes] = useState([])
  const [mediaLibraryCategoryId, setMediaLibraryCategoryId] = useState("")
  const [modalVisible, setModalVisible] = useState(false)

  const client = useMemo(() => buildApiClient(apiKey), [apiKey])

  // This modal needs to interact with our Stimulus and Quill components, so
  // we need to listen for events that are dispatched from those components.
  // Once we receive a choose-media-library-resource event, we'll activate the
  // modal.
  // 
  // It's possible we'll have multiple instances of this modal on the page, so
  // we check the UUID before deciding whether to activate.
  useEffect(() => {
    window.addEventListener('choose-media-library-resource', (event) => {
      const { detail } = event
      if(detail.mediaLibraryUuid == mediaLibraryUuid) {
        setModalVisible(true)
      }
    })

    return () => {
      window.removeEventListener('choose-media-library-resource')
    }
  }, [])

  // Load Media Types
  useEffect(() => {
    if(modalVisible && mediaLibraryTypes.length == 0) {
      loadMediaLibraryTypes()
    }
  }, [modalVisible])




  // When a media library resource is selected, we need to dispatch an event
  // to any listeners that are waiting for that event and close the modal,
  function handleMediaSelected(mediaLibraryResource) {
    const event = new CustomEvent('media-library-resource-selected', {
      detail: {
        mediaLibraryUuid,
        success: true,
        mediaLibraryResource
      }
    })
    window.dispatchEvent(event)

    setModalVisible(false)
  }

  // If the user cancels the selection, we need to dispatch an event to any
  // listeners that are waiting for that event and close the modal.
  function handleMediaSelectionAborted() {
    const event = new CustomEvent('media-library-resource-selection-aborted', {
      detail: {
        mediaLibraryUuid,
        success: false
      }
    })
    window.dispatchEvent(event)

    setModalVisible(false)
  }

  async function loadMediaLibraryTypes() {
    const url = `/api/v1/media_library_types`
    const response = await client.get(url)
    setMediaLibraryTypes(response.data.data)
    setReady(true)
  }

  const mediaLibraryType = mediaLibraryTypes.find(mediaLibraryType => mediaLibraryType.id == mediaLibraryTypeValue)
  const mediaLibraryCategories = mediaLibraryType ? mediaLibraryType.attributes.mediaLibraryCategories : []

  return (
    <Modal show={modalVisible} onHide={handleMediaSelectionAborted} size="xl" className="modal-media-library-browser">
      <Modal.Header closeButton>
        <Modal.Title>Media Gallery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader loading={!ready}>
          <Row>
            <Col sm="3">
              <MediaLibraryTypeSelector
                mediaLibraryTypes={mediaLibraryTypes}
                value={mediaLibraryTypeValue}
                onChange={mediaLibraryTypeValue => {
                  setMediaLibraryTypeValue(mediaLibraryTypeValue)
                  setMediaLibraryCategoryId("")
                }}
              />
              <MediaLibraryCategorySelector
                mediaLibraryCategories={mediaLibraryCategories}
                value={mediaLibraryCategoryId}
                onChange={setMediaLibraryCategoryId}
              />
            </Col>
            <Col sm="9">
              <Gallery
                client={client}
                mediaLibraryType={mediaLibraryType}
                mediaLibraryTypeValue={mediaLibraryTypeValue}
                mediaLibraryCategoryId={mediaLibraryCategoryId}
                onChange={mediaLibraryResource => handleMediaSelected(mediaLibraryResource)}
              />
            </Col>
          </Row>
        </Loader>
      </Modal.Body>
    </Modal>
  );
}

export default ModalMediaLibraryBrowser;
