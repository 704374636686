import React from 'react';
import { Nav } from 'react-bootstrap'

const MediaLibraryTypeSelector = ({ mediaLibraryTypes, value, onChange }) => (
  <Nav className="flex-column" variant="pills" activeKey={value} onSelect={onChange}>
    { mediaLibraryTypes.map((mediaLibraryType => 
      <Nav.Link key={mediaLibraryType.id} eventKey={mediaLibraryType.id}>{mediaLibraryType.attributes.name}</Nav.Link>
    ))}
  </Nav>
)

export default MediaLibraryTypeSelector;
