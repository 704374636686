import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import SearchBar from './SearchBar'
import ResourcePreview from './ResourcePreview'
import Pager from './Pager'
import NoMatches from './NoMatches'
import Loader from './Loader'

const per = 24

const Gallery = ({ client, mediaLibraryType, mediaLibraryTypeValue, mediaLibraryCategoryId, onChange }) => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [mediaLibraryResources, setMediaLibraryResources] = useState([])

  useEffect(() => {
    if(mediaLibraryTypeValue) {
      loadMediaLibraryResources({ mediaLibraryTypeValue, mediaLibraryCategoryId })
    }
  }, [mediaLibraryTypeValue, mediaLibraryCategoryId, search, page, per])
 

  function handleSelect(mediaLibraryResource) {
    onChange && onChange(mediaLibraryResource)
  }

  async function loadMediaLibraryResources({ mediaLibraryTypeValue, mediaLibraryCategoryId }) {
    setLoading(true)

    const params = {
      media_library_type_value: mediaLibraryTypeValue,
      media_library_category_id: mediaLibraryCategoryId,
      search, page, per
    }
    const url = `/api/v1/media_library_resources?${new URLSearchParams(params)}`
    const response = await client.get(url)
    setMediaLibraryResources(response.data.data)
    const totalPages = Math.ceil(response.data.meta.totalCount / per)
    setTotalPages(totalPages)

    setLoading(false)
  }


  return (
    <div>
      <SearchBar value={search} onChange={setSearch} />
      <Loader loading={loading}>
        { mediaLibraryResources.length > 0 ?
          <>
            <Row>
              { mediaLibraryResources.map((mediaLibraryResource) => (
                <Col sm={4} key={mediaLibraryResource.id}>
                  <ResourcePreview mediaLibraryResource={mediaLibraryResource} onSelect={handleSelect} />
                </Col>
              ))}
            </Row>
            <Pager page={page} per={per} handleChange={setPage} totalPages={totalPages} />
          </>
          :
          <NoMatches
            mediaLibraryType={mediaLibraryType}
            search={search}
          />
        }

      </Loader>
    </div>
  )
}

export default Gallery;
