import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'
import getVideoId from 'get-video-id'

const autoplay = 0

export default class extends Controller {
  static targets = [
    "player"
  ]

  static values = {
    url: String
  }

  connect() {
    const { id, service } = getVideoId(this.urlValue)

    switch(service) {
      case "youtube":
        this.playerTarget.innerHTML = `<div class="ratio ratio-16x9"><iframe class="embed-responsive-item" allowfullscreen="" frameborder="0" rel="0" loading="lazy" src="https://www.youtube.com/embed/${id}?rel=0&controls=1&showinfo=0&autoplay=${autoplay}"></iframe></div>`
        break;
      default:
        this.playerTarget.innerHTML = ``
        break;
    }
  }
}
