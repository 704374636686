import React from 'react'

const DEFAULT_DEBOUNCE_DELAY = 300

class DebouncedInput extends React.Component {
  state = {
    value: this.props.value
  }

  componentDidUpdate(prevProps) {
    if(prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value })
    }
  }

  handleChange = (e) => {
    const { onChange, debounceDelay } = this.props
    const value = e.target.value

    this.setState({ value }, () => {
      if(this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => onChange(value), debounceDelay || DEFAULT_DEBOUNCE_DELAY)
    })
  }

  render() {
    const { onChange, debounceDelay, ...remainingProps } = this.props
    return (
      <input {...remainingProps} value={this.state.value} onChange={this.handleChange} />
    )
  }
}

export default DebouncedInput
