import React from 'react'

const NoMatches = ({ search, mediaLibraryType }) => {
  if(search) {
    return (
      <p className="text-center">No matches found for "{search}".</p>
    )
  }
  else if(mediaLibraryType && mediaLibraryType.attributes.allowUpload) {
    return (
      <p className="text-center">No uploaded media was found.  You can visit Manage -&gt; Business Settings -&gt; <a href="/admin/media_library_resources/" target="_blank">Media Library</a> in order to upload saved files. </p>
    )
  }
  else {
    return (
      <p className="text-center">No media was found.</p>
    )
  
  }
}

export default NoMatches
