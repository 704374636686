import React, { useState, useEffect } from 'react'

const Loader = ({ loading, children }) => {
  const [showSpinner, setShowSpinner] = useState(false)

  // Add a small delay before showing the spinner to avoid flickering
  useEffect(() => {
    let timeout

    if(loading) {
      timeout = setTimeout(() => {
        setShowSpinner(true)
      }, 500)
    }
    else {
      clearTimeout(timeout)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [loading])

  if(loading && !showSpinner) {
    return null
  }
  else if(loading && showSpinner) {
    return (
      <div className="loading-dialog mt-3 mb-3">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
      </div>
    )
  }
  else {
    return children
  }
}

export default Loader

