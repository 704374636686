import React from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'react-bootstrap'


export default class Pager extends React.Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    per: PropTypes.number.isRequired,
    // totalPages: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  setPage = (page) => {
    const { handleChange } = this.props
    handleChange(page)
  }

  render() {
    const { page, per, totalPages } = this.props

    const showFirst = page > 1
    const showPrevious = page > 1
    const showNext = page < totalPages
    const showLast = page < totalPages

    if(totalPages > 1) {
      return (
        <div>
          <Pagination>
            { showFirst && 
              <Pagination.Item onClick={() => this.setPage(1)}> « First </Pagination.Item>
            }

            { showPrevious &&
              <Pagination.Item onClick={() => this.setPage(page - 1)}> ‹ Prev </Pagination.Item>
            }

            { (page - 2 > 1) &&
              <Pagination.Ellipsis disabled />
            }

            { (page - 1 > 1) &&
              <Pagination.Item onClick={() => this.setPage(page - 1)}> { page - 1 } </Pagination.Item>
            }

            <Pagination.Item active> { page } </Pagination.Item>

            { (page + 1 <= totalPages) &&
              <Pagination.Item onClick={() => this.setPage(page + 1)}> { page + 1 } </Pagination.Item>
            }

            { (page + 2 <= totalPages) &&
              <Pagination.Ellipsis disabled />
            }

            { showNext &&
              <Pagination.Item onClick={() => this.setPage(page + 1)}> Next ›</Pagination.Item>
            }
            { showLast &&
              <Pagination.Item onClick={() => this.setPage(totalPages)}> Last » </Pagination.Item>
            }
          </Pagination>
        </div>
      )
    }
    else {
      return null
    }
  }
}

