// https://github.com/kensnyder/quill-image-resize-module/issues/10

import Quill from 'quill'
import Delta from 'quill-delta'
import { QuillToolbarButton } from 'quill'
import BlotFormatter from 'quill-blot-formatter';
import ImageUpload from 'quill-plugin-image-upload';
import MagicUrl from 'quill-magic-url';
import { subscribeToDynamicContentChanges } from '../dynamic-content'
import 'quill-form'
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "./index.scss"
import { chooseMediaLibraryResourcePromise } from '../../components/MediaLibraryBrowser'


const mediaGallerySvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#444444" class="bi bi-images" viewBox="0 0 16 16"><path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/><path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z"/></svg>`


// https://github.com/kensnyder/quill-image-resize-module/issues/43
// BEGIN allow image alignment styles
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

const BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
// END allow image alignment styles



// Used for our custom image gallery
const BaseImageBlot = Quill.import('formats/image');

// Set the icon
class ImageGalleryBlot extends BaseImageBlot {
  static blotName = 'imageGallery';
}


Quill.register(ImageGalleryBlot);
// End used for our custom image gallery



// Fix issue with autocorrect deleting text when typing on a line with formatting
// https://github.com/quilljs/quill/issues/2096#issuecomment-399576957
const Inline = Quill.import('blots/inline');
class CustomColor extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    // Map <font> properties
    domNode.style.color = domNode.color;

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach(child => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    this.remove();

    return span;
  }
}
CustomColor.blotName = "customColor";
CustomColor.tagName = "FONT";
Quill.register(CustomColor, true);
// END fix issue with autocorrect deleting text when typing on a line with formatting

Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/imageUpload', ImageUpload);
Quill.register('modules/magicUrl', MagicUrl);

const loadQuill = () => {
  const nodes = Array.from(document.getElementsByClassName('quill-editor'))
  nodes.forEach(node => {
    if(node.classList.contains('quill-editor-attached')) return

    const value = node.value
    const id = node.id
    const apiKey = node.getAttribute('data-api-key')
    const mediaLibraryUuid = node.getAttribute('data-media-library-uuid')
    // const csrfToken = node.closest('form').querySelector('[name="authenticity_token"]').value

    const div = document.createElement('div');
    div.id = `quill__${id}`
    div.innerHTML = value
    node.parentNode.insertBefore( div, node );

    const containerForm = node.closest("form")
    containerForm.addEventListener("submit", event => {
      if(containerForm.getAttribute("quill-upload-in-progress") === 'true') {
        event.preventDefault()
        alert("Please wait until image is finished uploading before submitting")

        setTimeout(() => {
          containerForm.querySelectorAll('input[type="submit"]').forEach(btn => (btn.disabled = false))
        }, 500)
      }
    })
    // containerForm.addEventListener("submit", event => {
    //   event.preventDefault()

    //   if(containerForm.getAttribute("quill-upload-in-progress") === 'true') {
    //     setInterval(() => {
    //       console.log('Waiting for image upload to finish')
    //       containerForm.querySelectorAll('input[type="submit"]').forEach(btn => (btn.value = "Uploading..."))
    //       if(containerForm.getAttribute("quill-upload-in-progress") !== 'true') {
    //         containerForm.submit()
    //       }
    //     }, 500)
    //   }
    //   else {
    //     containerForm.submit()
    //   }
    // })
    
    // Disable tab indent on ordered lists since it causes errors
    // https://github.com/quilljs/quill/issues/979
    const bindings = {
      "indent": {
        key: "tab",
        format: ["list"],
        handler: function(range, context) {
          if(context.format.list == "ordered") {
            return false;
          } else {
            if (context.collapsed && context.offset !== 0) return true;
            this.quill.format('indent', '+1', Quill.sources.USER);
          }
        }
      }
    }

    const quill = new Quill(`#${div.id}`, {
      theme: 'snow',
      modules: {
        keyboard: {
          bindings
        },
        toolbar: {
          container: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }],
            ['bold', 'italic', 'underline', 'strike'], 
            [{ align: '' }, { align: 'center' }, { align: 'right' }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' },{ 'indent': '-1'}, { 'indent': '+1' }],
            mediaLibraryUuid ? ['link', 'image', 'imageGallery'] : ['link', 'image'],
          ],
          handlers: {
            'imageGallery': async function() {
              try {
                const mediaLibraryResource = await chooseMediaLibraryResourcePromise({ mediaLibraryUuid })
                console.log({ mediaLibraryResource })
                const imageUrl = mediaLibraryResource.attributes.url

                const format = 'imageGallery'
                if(!imageUrl) return
                const range = this.quill.getSelection(true);
                this.quill.updateContents(new Delta()
                  .retain(range.index)
                  .delete(range.length)
                  .insert({ [format]: imageUrl })
                  , Quill.sources.USER);
              }
              catch(e) {
                console.info(e)
                return
              }
            }
          }
        },
        form: {
          htmlField: node,
          updateOnBlur: true,
          updateOnChange: true
        },
        blotFormatter: {},
        magicUrl: {},
        imageUpload: {
          // url: '/api/v1/embedded_attachments',
          // method: 'POST',
          // withCredentials: false,
          // name: 'attachment',
          // callbackOK: (serverResponse, next) => {
          //   console.log(serverResponse)
          //   return next(serverResponse.data.attributes.url)
          // },
          // callbackKO: serverError => {
          //   console.log(serverError)
          //   alert(serverError)
          // }
          upload: file => {
            // return a Promise that resolves in a link to the uploaded image
            return new Promise((resolve, reject) => {
              const form = new FormData
              form.append('attachment', file)

              containerForm.setAttribute("quill-upload-in-progress", 'true')

              const xhr = new XMLHttpRequest;
              xhr.open("POST", "/api/v1/embedded_attachments", true);
              // xhr.setRequestHeader("X-CSRF-Token", csrfToken)
              xhr.setRequestHeader("API_KEY", apiKey)
              xhr.onload = () => {
                containerForm.setAttribute("quill-upload-in-progress", 'false')
                if(xhr.status === 200) {
                  const parsedResponse = JSON.parse(xhr.responseText)
                  const url = parsedResponse["data"]["attributes"]["url"]
                  debugger
                  resolve(url)
                }
                else {
                  alert("Unable to upload image")
                  reject("Unable to upload image")
                }
              }
              xhr.onerror = () => {
                containerForm.setAttribute("quill-upload-in-progress", 'false')
                alert("Unable to upload image")
                reject("Unable to upload image")
              }

              xhr.send(form)
            });
          }
        }
      }
    })

    node.classList.add('quill-editor-attached')
    const imageGalleryButton = quill.container.closest('form').querySelector(".ql-toolbar .ql-imageGallery")
    if(imageGalleryButton) {
      imageGalleryButton.innerHTML = mediaGallerySvg
    }
  })
}

document.addEventListener('DOMContentLoaded', loadQuill)
subscribeToDynamicContentChanges(loadQuill)
