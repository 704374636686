const chooseMediaLibraryResourcePromise = ({ mediaLibraryUuid }) => new Promise((resolve, reject) => {
  const event = new CustomEvent('choose-media-library-resource', {
    detail: {
      mediaLibraryUuid
    }
  })
  window.dispatchEvent(event)

  window.addEventListener('media-library-resource-selected', event => {
    resolve(event.detail.mediaLibraryResource)
  })
  window.addEventListener('media-library-resource-selection-aborted', event => {
    reject(new Error('Media library resource selection aborted'))
  })
})

export default chooseMediaLibraryResourcePromise;
