import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'


export default class extends Controller {
  static targets = [
    "addBlockButtonWrapper",
    "addBlockTemplate",
    "type"
  ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    this.typeTargets.forEach(typeTarget => {
      switch(typeTarget.value) {
        case "SupportBlock::SupportBlockHtml":
          showTarget(typeTarget.closest('.support-article-block').querySelector('.html-controls'))

          hideTarget(typeTarget.closest('.support-article-block').querySelector('.image-controls'))
          hideTarget(typeTarget.closest('.support-article-block').querySelector('.video-controls'))
          break;
        case "SupportBlock::SupportBlockImage":
          showTarget(typeTarget.closest('.support-article-block').querySelector('.image-controls'))

          hideTarget(typeTarget.closest('.support-article-block').querySelector('.html-controls'))
          hideTarget(typeTarget.closest('.support-article-block').querySelector('.video-controls'))
          break;
        case "SupportBlock::SupportBlockVideo":
          showTarget(typeTarget.closest('.support-article-block').querySelector('.video-controls'))

          hideTarget(typeTarget.closest('.support-article-block').querySelector('.html-controls'))
          hideTarget(typeTarget.closest('.support-article-block').querySelector('.image-controls'))
          break;
        default:
          throw new Error(`Invalid value: ${typeTarget.value}`)

      }
    })
  }

  addBlock(e) {
    e.preventDefault()

    const content = this.addBlockTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.addBlockButtonWrapperTarget.insertAdjacentHTML('beforebegin', content)
    dynamicContentLoaded()
    this.updateVisibility()
  }

  removeBlock(e) {
    e.preventDefault()
    const booking = e.target.closest('.support-article-block')
    booking.querySelector('.panel').remove()
    booking.querySelector(".destroy-attribute").value = true
    console.log({ booking })
    this.updateVisibility()
  }
}
