const EVENT_NAME = "dynamic-content-loaded"

const dynamicContentLoaded = () => {
  document.dispatchEvent(new Event(EVENT_NAME))
}

const subscribeToDynamicContentChanges = (callback) => {
  document.addEventListener(EVENT_NAME, callback)
}

export { dynamicContentLoaded, subscribeToDynamicContentChanges }
