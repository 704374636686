import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "actionDropdown",
    "bulkEditButton",
    "categoryWrapper",
    "checkbox",
    "designTemplateIds",
    "layoutWrapper",
    "packageSelectorWrapper",
    "selectAllCheckbox",
    "numberOfPhotosWrapper"
  ]

  connect() {
    this.updateCheckboxes()
    this.refreshActionDropdown()
  }

  refreshActionDropdown() {
    const action = this.actionDropdownTarget.value
    switch(action) {
      case "add_categories":
      case "remove_categories":
        if(this.hasCategoryWrapperTarget) showTarget(this.categoryWrapperTarget)
        if(this.hasLayoutWrapperTarget) hideTarget(this.layoutWrapperTarget)
        if(this.hasPackageSelectorWrapperTarget) hideTarget(this.packageSelectorWrapperTarget)
        if(this.hasNumberOfPhotosWrapperTarget) hideTarget(this.numberOfPhotosWrapperTarget)
        break;
      case "add_layouts":
      case "remove_layouts":
        if(this.hasCategoryWrapperTarget) hideTarget(this.categoryWrapperTarget)
        if(this.hasLayoutWrapperTarget)showTarget(this.layoutWrapperTarget)
        if(this.hasPackageSelectorWrapperTarget) hideTarget(this.packageSelectorWrapperTarget)
        if(this.hasNumberOfPhotosWrapperTarget) hideTarget(this.numberOfPhotosWrapperTarget)
        break;
      case "set_associated_packages":
        if(this.hasCategoryWrapperTarget) hideTarget(this.categoryWrapperTarget)
        if(this.hasLayoutWrapperTarget)hideTarget(this.layoutWrapperTarget)
        if(this.hasPackageSelectorWrapperTarget) showTarget(this.packageSelectorWrapperTarget)
        if(this.hasNumberOfPhotosWrapperTarget) hideTarget(this.numberOfPhotosWrapperTarget)
        break;
      case "delete_design_templates":
        if(this.hasCategoryWrapperTarget) hideTarget(this.categoryWrapperTarget)
        if(this.hasLayoutWrapperTarget)hideTarget(this.layoutWrapperTarget)
        if(this.hasPackageSelectorWrapperTarget) hideTarget(this.packageSelectorWrapperTarget)
        if(this.hasNumberOfPhotosWrapperTarget) hideTarget(this.numberOfPhotosWrapperTarget)
        break;
      case "set_number_of_photos":
        if(this.hasCategoryWrapperTarget) hideTarget(this.categoryWrapperTarget)
        if(this.hasLayoutWrapperTarget) hideTarget(this.layoutWrapperTarget)
        if(this.hasPackageSelectorWrapperTarget) hideTarget(this.packageSelectorWrapperTarget)
        if(this.hasNumberOfPhotosWrapperTarget) showTarget(this.numberOfPhotosWrapperTarget)
        break;
      default:
        throw new Error(`Unhandled value passed for action: ${action}`)
    }
  }

  updateCheckboxes() {
    const checked = this.checkboxTargets.filter(checkbox => checkbox.checked)
    const unchecked = this.checkboxTargets.filter(checkbox => !checkbox.checked)

    if(checked.length > 0) {
      showTarget(this.bulkEditButtonTarget)
    }
    else {
      hideTarget(this.bulkEditButtonTarget)
    }

    this.designTemplateIdsTarget.value = checked.map(checkbox => checkbox.value).join(",")
  }

  toggleSelectAll() {
    if(this.selectAllCheckboxTarget.checked) {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = true
      })
    }
    else {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = false
      })
    }
    this.updateCheckboxes()
  }


}
