import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'


export default class extends Controller {
  static values = {
    secret: String
  }
  static targets = ["secret"]

  show(e) {
    e.preventDefault()
    this.secretTarget.innerText = this.secretValue
  }
}
