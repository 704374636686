import React from 'react'
import DebouncedInput from '../../ui/DebouncedInput'

const SearchBar = ({ value, onChange }) => (
  <div className="form-group string optional">
    <DebouncedInput
      type="search"
      placeholder="Search Media Library"
      value={value}
      onChange={onChange}
      className="form-control string optional mb-4"
      autoFocus
      autoComplete="off"
    />
  </div>
)

export default SearchBar

