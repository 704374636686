import React from 'react'
import PropTypes from 'prop-types'

import { ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area } from 'recharts'

export default class PublicStatsChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    color: PropTypes.string
  }

  static defaultProps = {
    color: "#8884d8"
  }

  render() {
    const { data, label, color, type } = this.props
    const gradientId = `${type}-gradient`

    return (
      <div>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart data={data} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={color} stopOpacity={0}/>
            </linearGradient>
          </defs>
            <XAxis dataKey="formattedDateRange"/>
            <YAxis tickFormatter={val => `${val}`} />
            <CartesianGrid strokeDasharray="3 3"/>
            <Legend />
            <Tooltip formatter={val => `${Math.round(val)}`} />
            <Area
              stroke={color}
              type="natural"
              dataKey={type}
              fill={`url(#${gradientId})`}
              fillOpacity={1}
              name={label}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    )
  }
}
