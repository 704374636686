import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import PublicStatsChart from '../components/PublicStatsChart'
import "../helpers/quill"

const application = Application.start()
const sharedContext = require.context("../stimulus/shared_controllers", true, /\.js$/)
const localContext = require.context("../stimulus/marketing_controllers", true, /\.js$/)
application.load(definitionsFromContext(sharedContext))
application.load(definitionsFromContext(localContext))

const components = {
  "react-public-stats-chart": PublicStatsChart
}

document.addEventListener('DOMContentLoaded', () => {
  Object.entries(components).forEach(entry =>  {
    const className = entry[0]
    const component = entry[1]
    const nodes = Array.from(document.getElementsByClassName(className))
    nodes.forEach(node => {
      const data = JSON.parse(node.getAttribute('data-props'))
      ReactDOM.render(React.createElement(component, data), node)
    })
  })
})
