import React from 'react';
import { Nav } from 'react-bootstrap'

const MediaLibraryCategorySelector = ({ mediaLibraryCategories, value, onChange }) => {
  if(!mediaLibraryCategories.length) return null;

  return (
    <Nav className="flex-column mt-5" variant="pills" activeKey={value} onSelect={onChange}>
      <Nav.Link eventKey={""}>All</Nav.Link>
      { mediaLibraryCategories.map((mediaLibraryCategory => 
      <Nav.Link key={mediaLibraryCategory.id} eventKey={mediaLibraryCategory.id}>{mediaLibraryCategory.name}</Nav.Link>
      ))}
    </Nav>
  )
}

export default MediaLibraryCategorySelector;
